/*!

=========================================================
* NextJS Material Dashboard PRO - v1.1.0 Based on Material Dashboard PRO React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/nextjs-material-dashboard-pro
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// variables and mixins
@import "nextjs-material-dashboard-pro/variables";
@import "nextjs-material-dashboard-pro/mixins";
@import "nextjs-material-dashboard-pro/shadows";

// plugin css
@import "nextjs-material-dashboard-pro/plugins/plugin-nouislider";
@import "nextjs-material-dashboard-pro/plugins/plugin-react-datetime";
@import "nextjs-material-dashboard-pro/plugins/plugin-react-bootstrap-sweetalert";
@import "nextjs-material-dashboard-pro/plugins/plugin-react-chartist";
@import "nextjs-material-dashboard-pro/plugins/plugin-react-big-calendar";
@import "nextjs-material-dashboard-pro/plugins/plugin-react-jvectormap";
@import "nextjs-material-dashboard-pro/plugins/plugin-react-table";
@import "nextjs-material-dashboard-pro/plugins/plugin-react-tagsinput";

// Core CSS
@import "nextjs-material-dashboard-pro/misc";
@import "nextjs-material-dashboard-pro/fileupload";
@import "nextjs-material-dashboard-pro/fixed-plugin";
@import "nextjs-material-dashboard-pro/page-transition";

// Overrides
@import "override.scss";
